.button {
    background-color: red;
    font-family: fantasy;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem;
    cursor: pointer;

    border-radius: 18px 15px 15px 15px;
    -moz-border-radius: 18px 15px 15px 15px;
    -webkit-border-radius: 18px 15px 15px 15px;
    border: 0px solid #000000;
    padding: 4px;
    font-size: 20px;
}