.characterDetailsContainer {
  height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(69, 69, 167, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
  position: relative;
  top: 5rem;
  padding-top: 1rem;
}

.infoCharacter__name {
  text-transform: uppercase;
  font-family: "Banger";
  src: url(./../../../public/fonts/Bangers-Regular.ttf) format("truetype");
  font-size: 5rem;
  display: block;
  text-align: left;
  padding-left: 3rem;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0.5) 0%,
    rgba(69, 69, 167, 0.5) 50%,
    rgba(0, 212, 255, 0.5) 100%
  );
}

.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
}

.highResolution {
  position: relative;
  float: left;
  width: 30rem;
}

.infoCharacterDetailsContainer {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;

  .infoCharacter__left {
    width: 50%;
    height: 100%;
    position: relative;
    float: left;
    display: flex;
    min-height: 21rem;

    span {
      display: block;
      padding: 1rem;
      text-align: left;
    }
  }

  .infoCharacter__right {
    width: 50%;
    height: 100%;
    position: relative;
    float: left;

    .characterImage__mask {
      z-index: 7;
      background-color: rgba(0, 0, 0, 0.5);
      width: 30rem;
      overflow: hidden;
      background: transparent url(/public/img/loading.gif) center center
        no-repeat;
    }
  }


  .infoCharacter__left:hover .infoCharacter__description::before {
    content: "";
    background-color: var(--infinity-stone-space-transparent);
    z-index: 7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
  }

  .infoCharacter__left:hover .infoCharacter__description {
    cursor: pointer;
    content: "";
    background-color: var(--infinity-stone-space-transparent);
    z-index: 73;
    top: 10;
    left: 20;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
  }

  .infoCharacter__left {
    display: flex;

    .infoCharacter__description {
      opacity: 0;
      display: block;
      padding: 1rem;
      text-align: left;
      text-transform: uppercase;
      font-family: "Banger";
      src: url(./../../../public/fonts/Bangers-Regular.ttf) format("truetype");
    }
  }
}

.navigation__list {
  display: flex;
  list-style-type: none;
  margin: 0;
  flex-wrap: wrap;
  text-transform: uppercase;
  justify-content: center;
  gap: 2rem;
  background-color: #fff;
  font-size: 18px;
  bottom: -2px;
  right: -6px;
  color: black;
  transform: skew(-15deg);

  .navigation__item {
    cursor: pointer;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .navigation__item.comics:hover {
    border-bottom: 3px solid var(--comics);
    transform: scale(1.1);
  }

  .navigation__item.events:hover {
    border-bottom: 2px solid var(--events);
    transform: scale(1.1);
  }

  .navigation__item.series:hover {
    border-bottom: 2px solid var(--series);
    transform: scale(1.1);
  }

  .navigation__item.stories:hover {
    border-bottom: 2px solid var(--stories);
    transform: scale(1.1);
  }
}
