.slider {
    aspect-ratio: 1;
    position: relative;
    overflow: scroll;
    display: flex;
    scroll-snap-type: x mandatory;
}

.slider img {
    scroll-snap-align: center;
    width: 100%;
    height: 100%;
    left: 0;
    position: sticky;
    object-fit: cover;
}