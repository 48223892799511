.App {
  text-align: center;
  height: 100vh;
}

.logo {
  width: 158px;
  height: auto;
  left: 100px;
  cursor: pointer;
  top: 2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  background-color: #282c34;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 100;
}

footer {
  text-align: center;
  padding: 15px;
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #61dafb;
}

.favourites {
  cursor: pointer;
  float: right;
  padding-right: 20px;
  padding-top: 10px;
  background: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}