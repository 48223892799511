.comics__container {
  background: rgb(217, 130, 85);
  background: linear-gradient(90deg,
      rgba(217, 130, 85, 1) 0%,
      rgba(189, 112, 73, 1) 35%,
      rgba(236, 173, 141, 1) 100%);
  gap: 20px;
  /* Adjust the gap between comic items as needed */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.comic__item {
  flex: 1 0 21%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

/*
.comic__image {
  width: 18rem;
} */