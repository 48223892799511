:root {
  --infinity-stone-space: #101974; /* Purple - Space Stone */
  --infinity-stone-reality: #F5043B; /* Red - Reality Stone */
  --infinity-stone-power: #F90BF3; /* Yellow - Power Stone */
  --infinity-stone-mind: #FCEB03; /* Blue - Mind Stone */
  --infinity-stone-time: #04E5B1; /* Green - Time Stone */
  --infinity-stone-soul: #D98255; /* Indigo - Soul Stone */

  --infinity-stone-space-transparent: rgba(16, 25, 116, 0.55); /* Purple - Space Stone */
  --infinity-stone-reality-transparent: rgba(245, 4, 59, 0.55); /* Red - Reality Stone */
  --infinity-stone-power-transparent: rgba(249, 11, 243, 0.55); /* Yellow - Power Stone */
  --infinity-stone-mind-transparent: rgba(252, 235, 3, 0.55); /* Blue - Mind Stone */
  --infinity-stone-time-transparent: rgba(4, 229, 177, 0.55); /* Green - Time Stone */
  --infinity-stone-soul-transparent: rgba(217, 130, 85, 0.55); /* Indigo - Soul Stone */

  --comics: var(--infinity-stone-soul);
  --events: var(--infinity-stone-mind);
  --creators: var(--infinity-stone-reality);
  --series: var(--infinity-stone-power);
  --stories: var(--infinity-stone-time);
  --characters: var(--infinity-stone-space);

  --stories: #04E5B1; /* Color principal */
  --stories-light: #e0f8f4; /* Verde muy claro para el fondo */
  --stories-dark: #039f81; /* Tono más oscuro del verde */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
