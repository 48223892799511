img.card-thumb-frame__thumbnail {
    width: 16rem;
    z-index: 10;
}

.card {

    background-color: #292c78;
    border: 0px solid #000000;
    cursor: pointer;
    height: 400px;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    transition: transform .2s;
    /* Animation */
    width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.card:hover {
    transform: scale(1.05);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.card-description {
    color: white;
    margin: 1rem 1rem;
    text-align: justify;
    font-size: 16px;
    overflow-y: hidden;
}

.card-title {
    color: white;
    display: block;
    width: auto;
    background-color: #3a3a9b;
    box-shadow: aquamarine;
    font-family: "Banger", Verdana, Tahoma;
    font-size: 1.4rem;
}

.image-container {
    min-height: 257px;
    max-height: 257px;
}

.image-container>img {
    max-width: 100%;
    min-height: 215px;
    max-height: 203px;
}

.container {
    overflow: hidden;
}

.bg {
    width: 100%;
    height: 165px;
    border-radius: 0 0 100% 100%;
    background-color: #ffa12c;
    z-index: 2;
    position: relative;
    top: -43px;
}

.iconContainer {
    position: absolute;
    bottom: 0px;
    right: 0rem;
    margin-left: auto;
}