.stories__container{
    background: linear-gradient(to bottom right, var(--infinity-stone-time), rgba(0, 0, 0, 0.55));
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.full-screen {
    width: 100vw;  /* 100% del ancho de la ventana */
    height: 100vh; /* 100% del alto de la ventana */
    background-color: lightblue; /* Color de fondo para visualizar */
}