@font-face {
  font-family: "Banger";
  src: url(./../../../public/fonts/Bangers-Regular.ttf) format("truetype");
}

.comic__container {
  display: flex;
  flex-direction: column;
  width: 60rem;
  cursor: pointer;
}

.row {
  cursor: pointer;
  display: flex;
  height: 20rem;
}

.row__container {
  display: flex;
  flex-direction: row;
}

.row__strip30 {
  position: relative;
  border: 2px solid rgb(255, 255, 255);
  overflow: hidden;
}

.row__strip30:hover .strip__img,
.row__strip70:hover .strip__img {
  transform: scale(1.1);
}

.row__strip30.stoneSpace:hover .strip__mask::before {
  content: "";
  background-color: var(--infinity-stone-space-transparent);
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.row__strip30.stoneReality:hover .strip__mask::before {
  content: "";
  background-color: var(--infinity-stone-reality-transparent);
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.row__strip70.stoneSoul:hover .strip__mask::before {
  content: "";
  background-color: var(--infinity-stone-soul-transparent);
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.row__strip70.stoneTime:hover .strip__mask::before {
  content: "";
  background-color: var(--infinity-stone-time-transparent);
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.row__strip70.stonePower:hover .strip__mask::before {
  content: "";
  background-color: var(--infinity-stone-power-transparent);
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.row__strip30.stoneMind:hover .strip__mask::before {
  content: "";
  background-color: var(--infinity-stone-mind-transparent);
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.row__strip30:hover .strip__info.strip__info-character-position,
.row__strip70:hover .strip__info.strip__info-character-position {
  top: 40%;
  right: 33%;
}

.row__strip30:hover .strip__info.strip__info-character-position-new {
  top: 40%;
  left: 10%;
}

.row__strip30:hover .strip__info,
.row__strip70:hover .strip__info {
  opacity: 1;
  z-index: 9;
  transform: scale(1.1);
}

.row__strip70 {
  position: relative;
  border: 2px solid rgb(255, 255, 255);
  overflow: hidden;
}

.strip__mask {
  width: 100%;
  overflow: hidden;
  width: 100%;
  height: 24.5rem;
}

.strip__img {
  object-fit: contain;
  height: 100%;
  transition: all 300ms ease-in-out;
}

.strip__info {
  font-family: "Banger", Verdana, Tahoma;
  color: antiquewhite;
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 50%;
  transition: all 300ms ease-in-out;
  font-size: 4rem;
}
.strip__info.strip__info-character-position {
  top: 40%;
  right: 33%;
}

.strip__info.strip__info-character-position-new {
  top: 40%;
  left: 10%;
}

@media only screen and (max-width: 959px) {
  .row {
    cursor: pointer;
    display: table;
    height: 20rem;
  }
}
